import { useContext } from "react";
import { UserContext } from "../../contexts";
import { airflowSettingOptions, cultureSettingOptions } from "../../constants/settings-constants";
import { SettingsOption } from "../../types/common-types";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { TranslationsContext } from "../../contexts/translations-context";
import { BackButton } from "../back-button";

function getPickedSetting(event: SelectChangeEvent, settings: SettingsOption[]) {
  const selectedValue = event.target.value as string;
  return settings.find((e) => e.value === selectedValue);
}

export default function UserSettings() {
  const { user, setUser } = useContext(UserContext);
  const { translator, loadTranslations } = useContext(TranslationsContext);

  const handleCultureChange = (event: SelectChangeEvent) => {
    const pickedSetting = getPickedSetting(event, cultureSettingOptions);
    if (pickedSetting === undefined) return;

    setUser({ ...user, cultureSettings: pickedSetting });

    loadTranslations(pickedSetting.value);
  };

  const handleAirflowUnitChange = (event: SelectChangeEvent) => {
    const pickedSetting = getPickedSetting(event, airflowSettingOptions);
    if (pickedSetting === undefined) return;

    setUser({ ...user, airflowSettings: pickedSetting });
  };

  return (
    <Box width={500}>
      <BackButton />

      <Stack spacing={1} direction="column">
        <Typography component={"h5"} variant={"h5"}>
          {translator("settings")}
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="culture-select-label">{translator("language")}</InputLabel>
          <Select
            labelId="culture-select-label"
            id="culture-select"
            value={user.cultureSettings.value}
            label={translator("language")}
            onChange={handleCultureChange}
          >
            {cultureSettingOptions.map((option) => (
              <MenuItem key={`culture-${option.value}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="airflow-unit-select-label">{translator("airflow_unit")}</InputLabel>
          <Select
            labelId="airflow-unit-select-label"
            id="airflow-unit-select"
            value={user.airflowSettings.value}
            label={translator("airflow_unit")}
            onChange={handleAirflowUnitChange}
          >
            {airflowSettingOptions.map((option) => (
              <MenuItem key={`airflow-${option.value}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
}
