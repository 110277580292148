import { Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TranslationsContext } from "../contexts/translations-context";
import { ArrowBack } from "@mui/icons-material";

export const BackButton = ({ children }: any) => {
  const { translator } = useContext(TranslationsContext);
  const navigate = useNavigate();

  return (
    <Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
      {translator("back")}
    </Button>
  );
};
