import { Alert, Box, Card, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { SweflowProduct } from "../../types/sweflow-product";
import { SweflowApi } from "../../api/sweflow-api";
import LoadingSkeletons from "./loading-skeletons";
import ProductCard from "./product-card";
import SearchControl from "./search-control";
import { SelectedProductContext } from "../../contexts/selected-product-provider";
import { routes } from "../../routing/routes";
import { useNavigate } from "react-router-dom";
import { TranslationsContext } from "../../contexts/translations-context";
import { BackButton } from "../back-button";

function getFilteredProducts(loadedProducts: SweflowProduct[], searchText: string): SweflowProduct[] {
  if (searchText === "") return loadedProducts;

  const lowerCaseSearch = searchText.toLowerCase();

  return loadedProducts.filter(
    (p) => p.don.toLowerCase().includes(lowerCaseSearch) || p.donkod.toLowerCase().includes(lowerCaseSearch)
  );
}

export default function ProductList() {
  const { setSelectedProduct } = useContext(SelectedProductContext);
  const { translator } = useContext(TranslationsContext);
  const navigate = useNavigate();

  const [loadedProducts, setLoadedProducts] = useState<SweflowProduct[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const onProductClicked = (product: SweflowProduct) => {
    setSelectedProduct(product);
    navigate(routes.productInfo);
  };

  useEffect(() => {
    let ignore = false;

    const api = new SweflowApi();
    api
      .getProductsList()
      .then((products) => {
        if (ignore) return;
        setLoadedProducts(products);
        setLoading(false);
      })
      .catch((err) => {
        if (ignore) return;
        setErrorMessage(translator("unable_to_load_products"));
        setLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [setLoadedProducts, setLoading, translator]);

  const filteredProducts = useMemo(() => getFilteredProducts(loadedProducts, searchText), [loadedProducts, searchText]);

  return (
    <Box>
      <BackButton />

      <Stack spacing={1} direction={"column"}>
        <Typography component={"h5"} variant={"h5"}>
          {translator("find_k_factor")}
        </Typography>

        <Card sx={{ padding: 1 }}>
          <SearchControl onSearch={(newSearchText: string) => setSearchText(newSearchText)} />
        </Card>

        {loading ? (
          <LoadingSkeletons />
        ) : errorMessage != null ? (
          <Alert severity="error">{errorMessage}</Alert>
        ) : (
          <>
            <Box sx={{ maxHeight: "100vh", overflowY: "scroll" }}>
              {filteredProducts.length === 0 ? (
                <Typography component={"h5"} variant={"h5"}>
                  {translator("no_matching_products")}
                </Typography>
              ) : null}
              {filteredProducts.map((p, i) => (
                <Box key={`product-${i}`} sx={{ marginBottom: ".5em" }}>
                  <ProductCard product={p} onProductClicked={onProductClicked} />
                </Box>
              ))}
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
}
