import { useState, useEffect, useMemo, useContext } from "react";
import { SweflowApi } from "../../api/sweflow-api";
import { SweflowProduct } from "../../types/sweflow-product";
import { ArrowDropDown } from "@mui/icons-material";
import { Box, Stack, Alert, Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import LoadingSkeletons from "./loading-skeletons";
import ProductCard from "./product-card";
import { groupBy, toArray } from "../../helpers/grouping-helpers";
import { SelectedProductContext } from "../../contexts/selected-product-provider";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routing/routes";
import { TranslationsContext } from "../../contexts/translations-context";
import { BackButton } from "../back-button";
import ProductQuestionaire from "./product-questionaire/product-questionaire";

export default function ProductPicker() {
  const { setSelectedProduct } = useContext(SelectedProductContext);
  const { translator } = useContext(TranslationsContext);
  const navigate = useNavigate();

  const [loadedProducts, setLoadedProducts] = useState<SweflowProduct[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const [questionaireAnswers, setQuestionarieAnswers] = useState<string[]>([]);

  const onProductClicked = (product: SweflowProduct) => {
    setSelectedProduct(product);
    navigate(routes.productInfo);
  };

  useEffect(() => {
    let ignore = false;

    const api = new SweflowApi();
    api
      .getProductsList()
      .then((products) => {
        if (ignore) return;
        setLoadedProducts(products);
        setLoading(false);
      })
      .catch((err) => {
        if (ignore) return;
        setErrorMessage(translator("unable_to_load_products"));
        setLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [setLoadedProducts, setLoading, translator]);

  const filteredProducts = useMemo(() => {
    if (questionaireAnswers.length === 0) return loadedProducts;
    return loadedProducts.filter((p) => questionaireAnswers.every((qa) => p.categories.includes(qa)));
  }, [loadedProducts, questionaireAnswers]);

  const groupedProducts = useMemo(
    () => toArray(groupBy(filteredProducts, (p) => p.gruppnamn)).sort((a, b) => a.key.localeCompare(b.key)),
    [filteredProducts]
  );

  return (
    <Box alignItems="center">
      <BackButton />

      <Stack spacing={1} direction={"column"}>
        <Typography component={"h5"} variant={"h5"}>
          {translator("find_k_factor")}
        </Typography>

        {loading ? (
          <LoadingSkeletons />
        ) : errorMessage != null ? (
          <Alert severity="error">{errorMessage}</Alert>
        ) : (
          <>
            <ProductQuestionaire onAnswersUpdated={(newAnswers) => setQuestionarieAnswers(newAnswers)} />
            {groupedProducts.length === 0 ? (
              <Typography component={"h5"} variant={"h5"}>
                {translator("no_matching_products")}
              </Typography>
            ) : null}
            {groupedProducts.map((group) => (
              <Accordion key={group.key}>
                <AccordionSummary expandIcon={<ArrowDropDown />}>{translator(group.key)}</AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ maxHeight: "70vh", overflowY: "scroll" }}>
                    {group.value.map((p, i) => (
                      <Box key={`product-${i}`} sx={{ marginBottom: ".5em" }}>
                        <ProductCard key={`product-${i}`} product={p} onProductClicked={onProductClicked} />
                      </Box>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Stack>
    </Box>
  );
}
