import { Link, Stack } from "@mui/material";
import { useContext, useMemo } from "react";
import { Cultures } from "../../constants/cultures";
import { UserContext } from "../../contexts";
import { SweflowWaterProduct } from "../../types/sweflow-product";
import { TranslationsContext } from "../../contexts/translations-context";

export interface ProductLinkListProps {
  product: SweflowWaterProduct;
}

export function WaterProductLinkList(props: ProductLinkListProps) {
  const { product } = props;
  const { user } = useContext(UserContext);
  const { translator } = useContext(TranslationsContext);

  const instructionsFile = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.fileSv;
      case Cultures.deDE:
        return product.fileDe;
      case Cultures.enUS:
        return product.fileEn;
      default:
        return null;
    }
  }, [product.fileDe, product.fileEn, product.fileSv, user.cultureSettings.value]);

  const installationVideo = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.videoSvVideoMontering;
      case Cultures.deDE:
        return product.videoDeVideoInstalling;
      case Cultures.enUS:
        return product.videoEnVideoInstalling;
      default:
        return null;
    }
  }, [
    product.videoDeVideoInstalling,
    product.videoEnVideoInstalling,
    product.videoSvVideoMontering,
    user.cultureSettings.value,
  ]);

  const adjustmentVideo = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.videoSvVideoInjustering;
      case Cultures.deDE:
        return product.videoDeVideoEinstellung;
      case Cultures.enUS:
        return product.videoEnVideoAdjustment;
      default:
        return null;
    }
  }, [
    product.videoDeVideoEinstellung,
    product.videoEnVideoAdjustment,
    product.videoSvVideoInjustering,
    user.cultureSettings.value,
  ]);

  return (
    <Stack direction={"column"} spacing={1}>
      {instructionsFile && (
        <Link href={instructionsFile} target="_blank">
          {instructionsFile.split("/").at(-1)}
        </Link>
      )}
      {installationVideo && (
        <Link href={installationVideo} target="_blank">
          {translator("installation_video")}
        </Link>
      )}
      {adjustmentVideo && (
        <Link href={adjustmentVideo} target="_blank">
          {translator("adjustment_video")}
        </Link>
      )}
    </Stack>
  );
}
