import { useState } from "react";
import { SweflowProduct } from "../../types/sweflow-product";
import { Collapse, Alert, IconButton, AlertTitle } from "@mui/material";
import { Close } from "@mui/icons-material";

export interface CalculatorProductInfoProps {
  product: SweflowProduct;
  onClose: () => void;
}

export function CalculatorProductInfo(props: CalculatorProductInfoProps) {
  const { product, onClose } = props;

  const [open, setOpen] = useState(true);

  const getProductInfo = () => {
    if (product.kind === "SweflowAirDiffuser" && product.boxkod !== null && product.boxkod.length > 0) {
      return `${product.donkod} + ${product.boxkod}`;
    }

    return product.donkod;
  };

  return (
    <Collapse in={open}>
      <Alert
        severity="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{getProductInfo()}</AlertTitle>
      </Alert>
    </Collapse>
  );
}
