import { Search } from "@mui/icons-material";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useContext, useState } from "react";
import { TranslationsContext } from "../../contexts/translations-context";

export interface SearchControlProps {
  onSearch: (searchText: string) => void;
}

export default function SearchControl(props: SearchControlProps) {
  const { onSearch } = props;
  const { translator } = useContext(TranslationsContext);
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChanged = (newSearchText: string) => {
    setSearchText(newSearchText);
    onSearch(newSearchText);
  };

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <TextField
        id="search-input"
        variant="outlined"
        size="small"
        fullWidth
        value={searchText}
        onChange={(e) => handleSearchTextChanged(e.target.value)}
        InputProps={{
          placeholder: translator("enter_searchtext"),
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
