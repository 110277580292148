import { Link, Stack } from "@mui/material";
import { useContext, useMemo } from "react";
import { Cultures } from "../../constants/cultures";
import { UserContext } from "../../contexts";
import { SweflowAirDiffuser } from "../../types/sweflow-product";
import { TranslationsContext } from "../../contexts/translations-context";

export interface AirdiffuserLinkListProps {
  product: SweflowAirDiffuser;
}

export function AirdiffuserLinkList(props: AirdiffuserLinkListProps) {
  const { product } = props;
  const { user } = useContext(UserContext);
  const { translator } = useContext(TranslationsContext);

  const instructionsFile = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.fileSv;
      case Cultures.deDE:
        return product.fileDe;
      case Cultures.enUS:
        return product.fileEn;
      default:
        return null;
    }
  }, [product.fileDe, product.fileEn, product.fileSv, user.cultureSettings.value]);

  const instructionsVideoOne = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.videoSvInstruktionerVideo1;
      case Cultures.deDE:
        return product.videoDeAnweisungVideo1;
      case Cultures.enUS:
        return product.videoEnInstructionsVideo1;
      default:
        return null;
    }
  }, [
    product.videoDeAnweisungVideo1,
    product.videoEnInstructionsVideo1,
    product.videoSvInstruktionerVideo1,
    user.cultureSettings.value,
  ]);

  const instructionsVideoTwo = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.videoSvInstruktionerVideo2;
      case Cultures.deDE:
        return product.videoDeAnweisungVideo2;
      case Cultures.enUS:
        return product.videoEnInstructionsVideo2;
      default:
        return null;
    }
  }, [
    product.videoDeAnweisungVideo2,
    product.videoEnInstructionsVideo2,
    product.videoSvInstruktionerVideo2,
    user.cultureSettings.value,
  ]);

  return (
    <Stack direction={"column"} spacing={1}>
      {instructionsFile && (
        <Link href={instructionsFile} target="_blank">
          {instructionsFile.split("/").at(-1)}
        </Link>
      )}
      {instructionsVideoOne && (
        <Link href={instructionsVideoOne} target="_blank">
          {translator("instructions_video_one")}
        </Link>
      )}
      {instructionsVideoTwo && (
        <Link href={instructionsVideoTwo} target="_blank">
          {translator("instructions_video_two")}
        </Link>
      )}
    </Stack>
  );
}
