import { MeasurementUnit } from "../../constants/units";

export const convert = (
  value: number,
  fromUnit: MeasurementUnit,
  toUnit: MeasurementUnit,
  numDecimals: number | null = null
): number => {
  if (toUnit !== MeasurementUnit.LiterPerSecond) throw new Error(`Conversion to ${toUnit} not supported!`);

  const getConvertedValue = () => {
    switch (fromUnit) {
      case MeasurementUnit.CubicMeterPerHour:
        return value * 0.27;
      case MeasurementUnit.CubicMeterPerSecond:
        return value * 1000;
      case MeasurementUnit.LiterPerSecond:
        return value;
      default:
        throw new Error(`Conversion from ${fromUnit} to ${toUnit} not supported!`);
    }
  };

  const returnValue = getConvertedValue();

  return numDecimals !== null ? Number.parseFloat(returnValue.toFixed(numDecimals)) : returnValue;
};
