import { Alert, AlertTitle } from "@mui/material";
import { SweflowNozzleSetting } from "../../types/sweflow-product";
import { useContext, useMemo } from "react";
import { MeasurementUnit } from "../../constants/units";
import { UserContext } from "../../contexts";
import { convert } from "./unit-conversion";
import { calculateAngle } from "./calculator-helper";
import { TranslationsContext } from "../../contexts/translations-context";

export interface CalculatorNozzleAngleInfoProps {
  airflow: number;
  pressure: number;
  nozzleTable: SweflowNozzleSetting[];
}

export function CalculatorNozzleAngleInfo(props: CalculatorNozzleAngleInfoProps) {
  const { airflow, pressure, nozzleTable } = props;
  const { user } = useContext(UserContext);
  const { translator } = useContext(TranslationsContext);

  const calculatedAngle = useMemo(() => {
    const airflowFromUnit = MeasurementUnit[user.airflowSettings.value as MeasurementUnit];
    const airflowLps = convert(airflow, airflowFromUnit, MeasurementUnit.LiterPerSecond);

    if (pressure === 0) return 0;

    const rawAngle = calculateAngle(airflowLps, pressure, nozzleTable);
    return Math.round(rawAngle ?? 0);
  }, [airflow, nozzleTable, pressure, user.airflowSettings.value]);

  return (
    <Alert severity="info">
      <AlertTitle>
        {translator("nozzle_configuration")}: {calculatedAngle} °
      </AlertTitle>
    </Alert>
  );
}
