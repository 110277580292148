import { createHashRouter } from "react-router-dom";
import Calculator from "../components/calculator/calculator";
import { routes } from "./routes";
import ErrorPage from "./error-page";
import ProductInfo from "../components/product-select/product-info";
import ProductPicker from "../components/product-select/product-picker";
import ProductSearch from "../components/product-select/product-search";
import UserSettings from "../components/user-settings/user-settings";

export const router = createHashRouter([
  {
    path: routes.calculator,
    element: <Calculator />,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.productPicker,
    element: <ProductPicker />,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.productSearch,
    element: <ProductSearch />,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.productInfo,
    element: <ProductInfo />,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.settings,
    element: <UserSettings />,
    errorElement: <ErrorPage />,
  },
]);
