import { Link } from "@mui/material";
import { SweflowProduct } from "../../types/sweflow-product";
import { useContext, useMemo } from "react";
import { TranslationsContext } from "../../contexts/translations-context";
import { env } from "../../env";

const SPC_BASE_URL = env.REACT_APP_SPC_BASE_URL as string;

export interface SpcLinkProps {
  product: SweflowProduct;
}

export default function SpcLink(props: SpcLinkProps) {
  const { product } = props;
  const { translator } = useContext(TranslationsContext);
  const spcLink = useMemo(() => `${SPC_BASE_URL}/?productId=${product.productReference}`, [product.productReference]);

  return (
    <>
      {product.spcSupported && (
        <Link href={spcLink} target="_blank">
          {translator("open_in_spc")}
        </Link>
      )}
    </>
  );
}
