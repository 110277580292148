declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_SWEFLOW_API_BASE_URL: string;
  REACT_APP_SPC_BASE_URL: string;
};

export const env: EnvType = {
  ...process.env,
  ...window.env,
};
