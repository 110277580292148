import { SweflowProduct } from "../types/sweflow-product";
import { env } from "../env";
import { ApiBase } from "./api-base";
import { Dictionary } from "../types/common-types";

const SWEFLOW_API_BASE_URL = env.REACT_APP_SWEFLOW_API_BASE_URL as string;

const ENDPOINT = {
  productList: "/products/list",
  productVariants: "/products/variants",
  translations: "/translations",
};

export class SweflowApi extends ApiBase {
  constructor(baseUrl = SWEFLOW_API_BASE_URL) {
    super(baseUrl);
  }

  async getProductsList(): Promise<SweflowProduct[]> {
    const response = this.get(ENDPOINT.productList);
    return response;
  }

  async getProductVariants(product: SweflowProduct): Promise<SweflowProduct[]> {
    const productCode = product.donkod;
    const url = `${ENDPOINT.productVariants}?productCode=${productCode}`;
    const response = this.get(url);
    return response;
  }

  async getTranslations(culture: string): Promise<Dictionary> {
    const url = `${ENDPOINT.translations}/${culture}`;
    const response = this.get(url);
    return response;
  }
}
