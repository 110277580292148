import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { SweflowProduct } from "../../types/sweflow-product";
import { useContext, useMemo } from "react";
import { UserContext } from "../../contexts";
import { Cultures } from "../../constants/cultures";

const fallbackImageUrl = "https://placehold.co/400x400/png";

export interface ProductCardProps {
  product: SweflowProduct;
  onProductClicked: (product: SweflowProduct) => void;
}

export default function ProductCard(props: ProductCardProps) {
  const { user } = useContext(UserContext);

  const { product, onProductClicked } = props;

  const productDescription = useMemo(() => {
    switch (user.cultureSettings.value) {
      case Cultures.svSE:
        return product.descriptionSv;
      case Cultures.deDE:
        return product.descriptionDe;
      case Cultures.enUS:
        return product.descriptionEn;
      default:
        return product.descriptionEn;
    }
  }, [product.descriptionDe, product.descriptionEn, product.descriptionSv, user.cultureSettings.value]);

  return (
    <Card
      sx={{
        display: "flex",
        "&:hover": {
          cursor: "pointer",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px",
        },
      }}
      onClick={() => onProductClicked(product)}
    >
      <CardMedia component="img" sx={{ width: 150 }} image={product.fileJpg || fallbackImageUrl} alt="product-image" />
      <Stack direction={"column"} spacing={1}>
        <CardContent>
          <Stack direction={"column"} spacing={1}>
            <Typography component="h6">{product.donkod}</Typography>
            <Typography variant="subtitle1" color="text.secondary" textAlign={"start"} component="p">
              {productDescription}
            </Typography>
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
}
