import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Dictionary } from "../types/common-types";
import { SweflowApi } from "../api/sweflow-api";
import { UserContext } from "./user-provider";

interface TranslationsProviderProps {
  translator: (key: string, ...args: any[]) => string;
  loadTranslations: (applicationLanguage: string) => Promise<void>;
  applicationLanguage: string;
}

export const TranslationsContext = createContext({
  translator: () => "",
  loadTranslations: () => new Promise(() => {}),
  applicationLanguage: "",
} as TranslationsProviderProps);

export const TranslationsProvider = ({ children }: any) => {
  const { user } = useContext(UserContext);
  const [applicationLanguage, setApplicationLanguage] = useState<string>("");
  const translations = useRef({} as Dictionary);

  const translator = useCallback((key: string, ...args: any[]) => {
    if (args.length > 0 && translations.current[key]) {
      const updatedTranslation = args[0].reduce(
        (acc: string, val: string, i: number) => `${acc}`.replace(`{${i + 1}}`, val),
        translations.current[key]
      );
      return updatedTranslation as string;
    }

    return translations.current[key] ?? key;
  }, []);

  const loadTranslations = useCallback(async (applicationLanguage: string) => {
    const api = new SweflowApi();
    const response = await api.getTranslations(applicationLanguage);

    translations.current = response;
    setApplicationLanguage(applicationLanguage);
  }, []);

  useEffect(() => {
    let ignore = false;
    if (ignore) return;

    loadTranslations(user.cultureSettings.value);

    return () => {
      ignore = true;
    };
  });

  return (
    <TranslationsContext.Provider
      value={{
        translator,
        loadTranslations,
        applicationLanguage,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};
