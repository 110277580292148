import { createContext, useCallback, useEffect, useState } from "react";
import { User } from "../types/common-types";
import { cultureSettingOptions, airflowSettingOptions } from "../constants/settings-constants";

const localStorageKey = "sweflow_user";

interface UserProviderProps {
  user: User;
  setUser: (user: User) => void;
}

const defaultUser: User = {
  airflowSettings: airflowSettingOptions[0],
  cultureSettings: cultureSettingOptions[0],
};

export const UserContext = createContext({} as UserProviderProps);

export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState(defaultUser);

  const setUserInternal = useCallback(
    (user: User) => {
      setUser(user);
      localStorage.setItem(localStorageKey, JSON.stringify(user));
    },
    [setUser]
  );

  useEffect(() => {
    let ignore = false;
    if (ignore) return;

    const localStorageUser = localStorage.getItem(localStorageKey);
    if (localStorageUser !== null) {
      const loadedUser = JSON.parse(localStorageUser) as User;
      setUser(loadedUser);
    }

    return () => {
      ignore = true;
    };
  }, [setUser]);

  return <UserContext.Provider value={{ user, setUser: setUserInternal }}>{children}</UserContext.Provider>;
};

export default UserProvider;
