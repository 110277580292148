export class ApiBase {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async get(relativePath: string) {
    const res = await fetch(`${this.baseUrl}${relativePath}`, {
      headers: this.addHeaders(),
      method: "GET",
    });
    return this.handleResponse(res);
  }

  async post(relativePath: string, body: any) {
    const res = await fetch(`${this.baseUrl}${relativePath}`, {
      headers: this.addHeaders(),
      method: "POST",
      body: JSON.stringify(body),
    });
    return this.handleResponse(res);
  }

  async handleResponse(res: Response) {
    if (res.status === 200 || res.status === 201 || res.status === 204) {
      const text = await res.text();
      try {
        return JSON.parse(text);
      } catch {
        return text;
      }
    } else if (res.status === 401) {
      //   redirectIdentityServer();
      return;
    } else {
      console.error(res);
    }

    return res;
  }

  private addHeaders() {
    const headers = new Headers();

    headers.append("content-type", "application/json");
    headers.append("Content-Type", "application/json");

    return headers;
  }
}
