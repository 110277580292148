import { Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useRouteError } from "react-router-dom";
import { TranslationsContext } from "../contexts/translations-context";

export default function ErrorPage() {
  const { translator } = useContext(TranslationsContext);
  const error = useRouteError() as any;

  return (
    <Stack spacing={2} direction={"column"} alignItems={"center"}>
      <Typography component="h2" variant="h2">
        {translator("oops")}
      </Typography>
      <Typography component="p" variant="h5">
        {translator("an_unexpected_error_occurred")}
      </Typography>
      <Typography component="i" variant="h5">
        {error.statusText || error.message}
      </Typography>
    </Stack>
  );
}
