import { useContext, useMemo, useState } from "react";
import { TranslationsContext } from "../../../contexts/translations-context";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { QuestionaireAnswer, questionaireQuestions } from "./questionaire-data";

export interface ProductQuestionaireProps {
  onAnswersUpdated: (answerValues: string[]) => void;
}

interface AnsweredQuestion {
  questionId: number;
  answer: QuestionaireAnswer;
}

export default function ProductQuestionaire(props: ProductQuestionaireProps) {
  const { onAnswersUpdated } = props;
  const { translator } = useContext(TranslationsContext);
  const [activeQuestion, setActiveQuestion] = useState(questionaireQuestions[0]);
  const [activeAnswers, setActiveAnswers] = useState<AnsweredQuestion[]>([]);

  const answerQuestion = (questionId: number, answer: QuestionaireAnswer) => {
    const newAnswer: AnsweredQuestion = { questionId, answer };
    const newActiveAnswers = activeAnswers
      .filter((a) => a.questionId !== questionId)
      .concat(newAnswer)
      .sort((e) => e.questionId);
    updateActiveAnswers(newActiveAnswers);

    trySetActiveQuestion(questionId + 1);
  };

  const activeAnswerClicked = (answer: AnsweredQuestion) => {
    const newActiveQuestionId = answer.questionId;
    trySetActiveQuestion(newActiveQuestionId);

    const newActiveAnswers = activeAnswers.filter((a) => a.questionId < newActiveQuestionId);
    updateActiveAnswers(newActiveAnswers);
  };

  const updateActiveAnswers = (newActiveAnswers: AnsweredQuestion[]) => {
    const sortedNewActiveAnswers = newActiveAnswers.sort((e) => e.questionId);
    setActiveAnswers(sortedNewActiveAnswers);

    const activeAnswerValues = sortedNewActiveAnswers.map((a) => a.answer.value);
    onAnswersUpdated(activeAnswerValues);
  };

  const trySetActiveQuestion = (questionId: number) => {
    const newActiveQuestion = questionaireQuestions.find((q) => q.questionId === questionId);
    if (newActiveQuestion) {
      setActiveQuestion(newActiveQuestion);
    }
  };

  const questionHeader = useMemo(
    () => `${activeQuestion.questionId + 1}. ${translator(activeQuestion.translationKey)}`,
    [activeQuestion.questionId, activeQuestion.translationKey, translator]
  );

  return (
    <Stack direction={"column"} spacing={0}>
      <Typography component="h6" variant="h6">
        {questionHeader}
      </Typography>
      {activeQuestion.answers.map((answer) => (
        <>
          <Button variant={"text"} onClick={() => answerQuestion(activeQuestion.questionId, answer)}>
            {translator(answer.translationKey)}
          </Button>
          <Divider component="div" />
        </>
      ))}
      <Box sx={{ marginTop: 1 }} display={"flex"} flexWrap={"wrap"} flexDirection={"row"}>
        {activeAnswers.map((a) => (
          <>
            <Chip
              sx={{ marginRight: 1, marginBottom: 1 }}
              label={translator(a.answer.translationKey)}
              onClick={() => activeAnswerClicked(a)}
            />
          </>
        ))}
      </Box>
    </Stack>
  );
}
