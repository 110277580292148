import {
  Typography,
  TableContainer,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  OutlinedInput,
  Button,
  Stack,
  Alert,
} from "@mui/material";
import { useState, useMemo, useContext } from "react";
import { SweflowWaterProduct, SweflowProduct } from "../../types/sweflow-product";
import { TranslationsContext } from "../../contexts/translations-context";

type PropertyKeyType = keyof SweflowWaterProduct;
type NozzleStatesType = { [key in PropertyKeyType]: number | null };

export interface WaterProductKFactorTableProps {
  waterProduct: SweflowWaterProduct;
  onAdd: (product: SweflowProduct, kFactor: number | null) => void;
}

export function WaterProductKFactorTable(props: WaterProductKFactorTableProps) {
  const { translator } = useContext(TranslationsContext);
  const { waterProduct, onAdd } = props;

  const [nozzleStates, setNozzleStates] = useState<NozzleStatesType>({} as NozzleStatesType);

  const totalNumberOfNozzles = useMemo(() => waterProduct.wbvariantDysnumber ?? 0, [waterProduct.wbvariantDysnumber]);
  const numUsedNozzles = useMemo(
    () =>
      Object.values(nozzleStates).reduce((acc, cur) => {
        const accumulated = parseInt(acc?.toString() ?? "0");
        const current = parseInt(cur?.toString() ?? "0");
        return accumulated + current;
      }, 0) ?? 0,
    [nozzleStates]
  );
  const numNozzlesLeft = useMemo(() => totalNumberOfNozzles - numUsedNozzles, [numUsedNozzles, totalNumberOfNozzles]);

  const nozzleConfProperties: PropertyKeyType[] = useMemo(
    () => [
      "wbvariantAirflowconfLShort",
      "wbvariantAirflowconfLLong",
      "wbvariantAirflowconfMShort",
      "wbvariantAirflowconfMLong",
      "wbvariantAirflowconfHShort",
      "wbvariantAirflowconfHLong",
      "wbvariantAirflowconfClosedShort",
      "wbvariantAirflowconfClosedLong",
    ],
    []
  );

  const nozzleConfPropertiesToUse = useMemo(
    () => nozzleConfProperties.filter((name) => waterProduct[name] != null),
    [nozzleConfProperties, waterProduct]
  );

  const handleNozzleCountChanged = (
    propertyName: PropertyKeyType,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newNozzleCount = event.target.value as unknown as number;
    const newNozzleStates = { ...nozzleStates, [propertyName]: newNozzleCount };
    setNozzleStates(newNozzleStates);
  };

  const totalKFactor = useMemo(() => {
    const internalKFactor = nozzleConfPropertiesToUse.reduce((acc, cur) => {
      const nozzleCount = nozzleStates[cur] ?? 0;
      const propertyConstant = (waterProduct[cur] ?? 0) as number;
      const nozzleKFactor = nozzleCount * propertyConstant;

      return acc + nozzleKFactor;
    }, 0);

    return internalKFactor;
  }, [nozzleConfPropertiesToUse, nozzleStates, waterProduct]);

  const isAddButtonDisabled = useMemo(() => numNozzlesLeft !== 0, [numNozzlesLeft]);

  return (
    <Stack direction={"column"} spacing={1}>
      {waterProduct.adjustableNozzles && (
        <TableContainer component={Card}>
          <Table aria-label="product table">
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {translator("add_to_calculation")}
                </TableCell>
                <TableCell align="right">
                  <Button variant="outlined" size="small" onClick={() => onAdd(waterProduct, null)}>
                    +
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {waterProduct.wbvariantDysnumber && (
        <>
          <TableContainer component={Card}>
            <Table aria-label="product table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>{translator("side_name")}</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>{translator("number_of_nozzles")}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nozzleConfPropertiesToUse.map((propertyKey, index) => (
                  <TableRow key={`${propertyKey}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {translator(propertyKey)}
                    </TableCell>
                    <TableCell align="right">
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          id="outlined-kfactor-value"
                          type="number"
                          value={nozzleStates[propertyKey] ?? 0}
                          onChange={(e) => handleNozzleCountChanged(propertyKey, e)}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator("num_nozzles_left")}
                  </TableCell>
                  <TableCell align="right">{numNozzlesLeft}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {numNozzlesLeft < 0 && <Alert severity="error">{translator("too_many_nozzles")}</Alert>}

          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography component={"span"}>
              {translator("total_k_factor")}: <b>{totalKFactor.toFixed(3)}</b>
            </Typography>

            <Button
              variant="contained"
              disabled={isAddButtonDisabled}
              onClick={() => onAdd(waterProduct, totalKFactor)}
            >
              {translator("add_product")}
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}
