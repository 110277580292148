import { SweflowNozzleSetting } from "../../types/sweflow-product";

export const calculatePressure = (airflowLps: number, kFactor: number): number => {
  return Math.pow(airflowLps / kFactor, 2);
};

export const calculateAirflowLps = (kFactor: number, pressurePa: number): number => {
  return kFactor * Math.sqrt(pressurePa);
};

export const calculateKFactor = (airflowLps: number, pressurePa: number): number => {
  return airflowLps / Math.sqrt(pressurePa);
};

export const calculateAngle = (airflowLps: number, pressurePa: number, nozzleTable: SweflowNozzleSetting[]) => {
  const kFactor = calculateKFactor(airflowLps, pressurePa);

  for (let i = 1; i < nozzleTable.length; i++) {
    const nozzleItem = nozzleTable[i];
    if (nozzleItem.kFactor <= kFactor) continue;

    const smallerValue = nozzleTable[i - 1];
    const largerValue = nozzleItem;

    const deltaKFactor = largerValue.kFactor - smallerValue.kFactor;
    const deltaAngle = largerValue.angle - smallerValue.angle;

    const kFactorIncreasePerDegree = deltaKFactor / deltaAngle;
    const kFactorDiff = kFactor - smallerValue.kFactor;

    const result = smallerValue.angle + kFactorDiff / kFactorIncreasePerDegree;

    return result;
  }
};
