import { Skeleton, Stack } from "@mui/material";

export default function LoadingSkeletons() {
  return (
    <>
      <Stack direction={"column"} spacing={1}>
        <Skeleton variant="rounded" height={100} animation="wave" />
        <Skeleton variant="rounded" height={100} animation="wave" />
        <Skeleton variant="rounded" height={100} animation="wave" />
        <Skeleton variant="rounded" height={100} animation="wave" />
        <Skeleton variant="rounded" height={100} animation="wave" />
      </Stack>
    </>
  );
}
