import { SettingsOption } from "../types/common-types";
import { Cultures } from "./cultures";
import { MeasurementUnit } from "./units";

export const cultureSettingOptions: SettingsOption[] = [
  {
    value: Cultures.enUS,
    label: "English",
  },
  {
    value: Cultures.svSE,
    label: "Svenska",
  },
  {
    value: Cultures.deDE,
    label: "Deutsch",
  },
];

export const airflowSettingOptions: SettingsOption[] = [
  {
    value: MeasurementUnit.LiterPerSecond,
    label: "l/s",
  },
  {
    value: MeasurementUnit.CubicMeterPerHour,
    label: "m3/h",
  },
  {
    value: MeasurementUnit.CubicMeterPerSecond,
    label: "m3/s",
  },
];
