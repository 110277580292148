export interface QuestionaireQuestion {
  questionId: number;
  translationKey: string;
  answers: QuestionaireAnswer[];
}

export interface QuestionaireAnswer {
  translationKey: string;
  value: string;
}

export const questionaireQuestions: QuestionaireQuestion[] = [
  {
    questionId: 0,
    translationKey: "questionaire_product_placement",
    answers: [
      {
        translationKey: "questionaire_placement_roof",
        value: "roof",
      },
      {
        translationKey: "questionaire_placement_wall",
        value: "wall",
      },
      {
        translationKey: "questionaire_placement_floor",
        value: "floor",
      },
      {
        translationKey: "questionaire_placement_canal",
        value: "canal",
      },
    ],
  },
  {
    questionId: 1,
    translationKey: "questionaire_product_supply_exhaust",
    answers: [
      {
        translationKey: "questionaire_supply_exhaust_supply",
        value: "supply",
      },
      {
        translationKey: "questionaire_supply_exhaust_exhaust",
        value: "exhaust",
      },
    ],
  },
  {
    questionId: 2,
    translationKey: "questionaire_product_type",
    answers: [
      {
        translationKey: "questionaire_product_type_water",
        value: "water",
      },
      {
        translationKey: "questionaire_product_type_no_water",
        value: "no-water",
      },
    ],
  },
  {
    questionId: 3,
    translationKey: "questionaire_product_suspended_recessed",
    answers: [
      {
        translationKey: "questionaire_product_suspended_recessed_suspended",
        value: "suspended",
      },
      {
        translationKey: "questionaire_product_suspended_recessed_recessed",
        value: "recessed",
      },
    ],
  },
  {
    questionId: 4,
    translationKey: "questionaire_product_shape",
    answers: [
      {
        translationKey: "questionaire_product_shape_rectangular",
        value: "rectangular",
      },
      {
        translationKey: "questionaire_product_shape_circular",
        value: "circular",
      },
      {
        translationKey: "questionaire_product_shape_other",
        value: "other-form",
      },
    ],
  },
  {
    questionId: 5,
    translationKey: "questionaire_product_perforated_nozzles",
    answers: [
      {
        translationKey: "questionaire_product_perforated_nozzles_perforated",
        value: "perforated",
      },
      {
        translationKey: "questionaire_product_perforated_nozzles_nozzles",
        value: "nozzles",
      },
      {
        translationKey: "questionaire_product_perforated_nozzles_other",
        value: "other",
      },
    ],
  },
];
