import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

export const CenterComponent = ({ children }: any) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const colCount = useMemo(() => {
    if (smallScreen) return 12;
    if (mediumScreen) return 8;
    return 3;
  }, [mediumScreen, smallScreen]);

  const colWidth = useMemo(() => {
    if (smallScreen || mediumScreen) return "unset";
    return "25vw";
  }, [mediumScreen, smallScreen]);

  return (
    <Grid container spacing={1} direction="column" paddingX={1} alignItems="center" sx={{ minHeight: "100vh" }}>
      <Grid item xs={colCount} sx={{ width: colWidth }}>
        {children}
      </Grid>
    </Grid>
  );
};
