import { createContext, useState } from "react";
import { SweflowProduct } from "../types/sweflow-product";

type SelectedProductType = SweflowProduct | null;
type SelectedCalculatorKFactorType = number | null;

interface SelectedProductProviderProps {
  selectedProduct: SelectedProductType;
  setSelectedProduct: React.Dispatch<React.SetStateAction<SelectedProductType>>;

  selectedCalculatorProduct: SelectedProductType;
  setSelectedCalculatorProduct: React.Dispatch<React.SetStateAction<SelectedProductType>>;

  selectedCalculatorKFactor: SelectedCalculatorKFactorType;
  setSelectedCalculatorKFactor: React.Dispatch<React.SetStateAction<SelectedCalculatorKFactorType>>;
}

export const SelectedProductContext = createContext({
  selectedProduct: null,
  selectedCalculatorProduct: null,
  selectedCalculatorKFactor: null,
} as SelectedProductProviderProps);

export const SelectedProductProvider = ({ children }: any) => {
  const [selectedProduct, setSelectedProduct] = useState<SelectedProductType>(null);
  const [selectedCalculatorProduct, setSelectedCalculatorProduct] = useState<SelectedProductType>(null);
  const [selectedCalculatorKFactor, setSelectedCalculatorKFactor] = useState<SelectedCalculatorKFactorType>(null);

  return (
    <SelectedProductContext.Provider
      value={{
        selectedProduct,
        setSelectedProduct,
        selectedCalculatorProduct,
        setSelectedCalculatorProduct,
        selectedCalculatorKFactor,
        setSelectedCalculatorKFactor,
      }}
    >
      {children}
    </SelectedProductContext.Provider>
  );
};
