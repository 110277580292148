import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import { calculateAirflowLps, calculateKFactor, calculatePressure } from "./calculator-helper";
import { convert } from "./unit-conversion";
import { MeasurementUnit } from "../../constants/units";
import { Alert, Box, Button, FormControl, InputAdornment, OutlinedInput, Stack } from "@mui/material";
import { SelectedProductContext } from "../../contexts/selected-product-provider";
import { CalculatorProductInfo } from "./calculator-product-info";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routing/routes";
import { CalculatorNozzleAngleInfo } from "./calculator-nozzle-angle-info";
import { TranslationsContext } from "../../contexts/translations-context";

const pressureAdjustStep: number = 5;

export default function Calculator() {
  const { user } = useContext(UserContext);
  const { translator } = useContext(TranslationsContext);
  const {
    selectedCalculatorProduct,
    setSelectedCalculatorProduct,
    selectedCalculatorKFactor,
    setSelectedCalculatorKFactor,
  } = useContext(SelectedProductContext);
  const navigate = useNavigate();

  const [airflow, setAirflow] = useState(0);
  const [pressure, setPressure] = useState(5);
  const [kFactor, setKFactor] = useState(0);

  const handleAirflowChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newAirflow = event.target.value as unknown as number;
    setAirflow(newAirflow);

    const shouldCalcKFactor =
      selectedCalculatorProduct?.kind === "SweflowWaterProduct" && selectedCalculatorProduct.nozzleSettings != null;
    if (shouldCalcKFactor) {
      calculateNewKFactor(newAirflow, pressure);
    } else if (kFactor > 0) {
      calculateNewPressure(kFactor, newAirflow);
    }
  };

  const handlePressureChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPressure = event.target.value as unknown as number;
    setNewPressure(newPressure);
  };

  const setNewPressure = (newPressure: number) => {
    setPressure(newPressure);

    const shouldCalcKFactor =
      selectedCalculatorProduct?.kind === "SweflowWaterProduct" && selectedCalculatorProduct.nozzleSettings != null;
    if (shouldCalcKFactor) {
      calculateNewKFactor(airflow, newPressure);
    } else {
      calculateNewAirflow(kFactor, newPressure);
    }
  };

  const handleKFactorChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newKFactor = event.target.value as unknown as number;
    setKFactor(newKFactor);

    calculateNewAirflow(newKFactor, pressure);
  };

  const calculateNewAirflow = useCallback(
    (kFactor: number, pressurePa: number) => {
      const newAirflowLps = calculateAirflowLps(kFactor, pressurePa);
      const toUnit = MeasurementUnit[user.airflowSettings.value as MeasurementUnit];
      const airflowUser = Math.round(convert(newAirflowLps, MeasurementUnit.LiterPerSecond, toUnit));

      setAirflow(airflowUser);
    },
    [user.airflowSettings.value]
  );

  const calculateNewPressure = (kFactor: number, airflow: number) => {
    const fromUnit = MeasurementUnit[user.airflowSettings.value as MeasurementUnit];
    const airflowLps = convert(airflow, fromUnit, MeasurementUnit.LiterPerSecond);

    const newPressurePa = Math.round(calculatePressure(airflowLps, kFactor));

    setPressure(newPressurePa);
  };

  const calculateNewKFactor = (airflow: number, pressurePa: number) => {
    const fromUnit = MeasurementUnit[user.airflowSettings.value as MeasurementUnit];
    const airflowLps = convert(airflow, fromUnit, MeasurementUnit.LiterPerSecond);

    const newKFactor = calculateKFactor(airflowLps, pressurePa);
    const roundedNewKFactor = parseFloat(newKFactor.toFixed(2));

    setKFactor(roundedNewKFactor);
  };

  const onProductRemoved = () => {
    setSelectedCalculatorProduct(null);
    setSelectedCalculatorKFactor(null);
  };

  const increasePressure = () => setNewPressure(parseFloat(pressure.toString()) + pressureAdjustStep);
  const decreasePressure = () =>
    pressure >= pressureAdjustStep ? setNewPressure(parseFloat(pressure.toString()) - pressureAdjustStep) : null;
  const searchProducts = () => navigate(routes.productSearch);
  const browseProducts = () => navigate(routes.productPicker);
  const changeSettings = () => navigate(routes.settings);

  useEffect(() => {
    let ignore = false;
    if (selectedCalculatorKFactor == null) return;
    if (ignore) return;

    setKFactor(selectedCalculatorKFactor);

    if (pressure > 0) {
      calculateNewAirflow(selectedCalculatorKFactor, pressure);
    }

    return () => {
      ignore = true;
    };
  }, [calculateNewAirflow, pressure, selectedCalculatorKFactor]);

  return (
    <Stack spacing={1} direction="column">
      <Box sx={{ maxHeight: "200px" }}>
        <img src="./swegon_logotype.svg" alt="Swegon Logo" />
      </Box>

      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="outlined-airflow-value"
          type="number"
          value={airflow}
          onChange={handleAirflowChanged}
          endAdornment={<InputAdornment position="end">{user.airflowSettings.label}</InputAdornment>}
          inputProps={{
            "aria-label": "airflow",
            min: 0,
          }}
        />
      </FormControl>

      <Stack spacing={1} direction="row">
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="outlined-pressure-value"
            type="number"
            value={pressure}
            onChange={handlePressureChanged}
            endAdornment={<InputAdornment position="end">Pa</InputAdornment>}
            inputProps={{
              "aria-label": "pressure",
              min: 0,
              step: 1,
            }}
          />
        </FormControl>
        <Button variant="contained" size="small" onClick={decreasePressure}>
          -
        </Button>
        <Button variant="contained" size="small" onClick={increasePressure}>
          +
        </Button>
      </Stack>

      {pressure < 5 ? <Alert severity="warning">{translator("pressure_below_5_pa")}</Alert> : null}

      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="outlined-kfactor-value"
          type="number"
          disabled={
            selectedCalculatorKFactor !== null ||
            (selectedCalculatorProduct?.kind === "SweflowWaterProduct" &&
              selectedCalculatorProduct.nozzleSettings != null)
          }
          value={kFactor}
          onChange={handleKFactorChanged}
          endAdornment={<InputAdornment position="end">k</InputAdornment>}
          inputProps={{
            "aria-label": "kfactor",
          }}
        />
      </FormControl>

      {selectedCalculatorProduct && (
        <Stack spacing={1} direction={"column"}>
          <CalculatorProductInfo product={selectedCalculatorProduct} onClose={onProductRemoved} />
          {selectedCalculatorProduct.kind === "SweflowWaterProduct" && selectedCalculatorProduct.nozzleSettings && (
            <CalculatorNozzleAngleInfo
              airflow={airflow}
              pressure={pressure}
              nozzleTable={selectedCalculatorProduct.nozzleSettings}
            />
          )}
        </Stack>
      )}

      <Stack direction={"column"} spacing={1} alignContent={"center"}>
        <Stack spacing={1} direction="row" justifyContent="center">
          <Button variant="contained" onClick={searchProducts}>
            {translator("search_products")}
          </Button>
          <Button variant="contained" onClick={browseProducts}>
            {translator("browse_products")}
          </Button>
        </Stack>
        <Button variant="text" onClick={changeSettings}>
          {translator("settings")}
        </Button>
      </Stack>
    </Stack>
  );
}
