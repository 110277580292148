import {
  TableContainer,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { SweflowAirDiffuser, SweflowProduct } from "../../types/sweflow-product";
import { useState, useEffect, useContext, useMemo } from "react";
import { SweflowApi } from "../../api/sweflow-api";
import { TranslationsContext } from "../../contexts/translations-context";

export interface AirDiffuserKFactorTableProps {
  airDiffuser: SweflowAirDiffuser;
  onAdd: (product: SweflowProduct, kFactor: number) => void;
}

export function AirDiffuserKFactorTable(props: AirDiffuserKFactorTableProps) {
  const { airDiffuser, onAdd } = props;

  const { translator } = useContext(TranslationsContext);

  const [variants, setVariants] = useState<SweflowAirDiffuser[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const hasBoxCode = useMemo(() => variants.some((e) => e.boxkod !== null), [variants]);

  useEffect(() => {
    let ignore = false;

    const api = new SweflowApi();
    api
      .getProductVariants(airDiffuser)
      .then((products) => {
        if (ignore) return;
        const airDiffusers = products.filter((p) => p.kind === "SweflowAirDiffuser") as SweflowAirDiffuser[];
        setVariants(airDiffusers);
        setLoading(false);
      })
      .catch((err) => {
        if (ignore) return;
        setErrorMessage(translator("unable_to_load_variants"));
        setLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [setVariants, setLoading, airDiffuser, translator]);

  return (
    <TableContainer component={Card}>
      <Table aria-label="product table">
        <TableHead>
          <TableRow>
            {hasBoxCode && (
              <TableCell>
                <b>{translator("plenum_box")}</b>
              </TableCell>
            )}
            <TableCell align={hasBoxCode ? "right" : "left"}>
              <b>{translator("k_factor")}</b>
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : errorMessage != null ? (
            <TableRow>
              <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                <Alert severity="error">{errorMessage}</Alert>
              </TableCell>
            </TableRow>
          ) : (
            variants.map((variant, index) => (
              <TableRow key={`${variant.donkod}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {hasBoxCode && (
                  <TableCell component="th" scope="row">
                    {variant.boxkod}
                  </TableCell>
                )}
                <TableCell align={hasBoxCode ? "right" : "left"}>{variant.kfaktor}</TableCell>
                <TableCell align="right">
                  <Button variant="outlined" size="small" onClick={() => onAdd(variant, variant.kfaktor)}>
                    +
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
