import { SweflowAirDiffuser, SweflowProduct, SweflowWaterProduct } from "../../types/sweflow-product";
import { useMemo, useContext } from "react";
import { SelectedProductContext } from "../../contexts/selected-product-provider";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routing/routes";
import { AirDiffuserKFactorTable } from "./air-diffuser-k-factor-table";
import { WaterProductKFactorTable } from "./water-product-k-factor-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts";
import { Cultures } from "../../constants/cultures";
import { TranslationsContext } from "../../contexts/translations-context";
import { ArrowDropDown } from "@mui/icons-material";
import { BackButton } from "../back-button";
import { WaterProductLinkList } from "./water-product-link-list";
import { AirdiffuserLinkList } from "./air-diffuser-link-list";
import SpcLink from "./spc-link";

export default function ProductInfo() {
  const { selectedProduct, setSelectedCalculatorProduct, setSelectedCalculatorKFactor } =
    useContext(SelectedProductContext);
  const { user } = useContext(UserContext);
  const { translator } = useContext(TranslationsContext);
  const navigate = useNavigate();

  const onAddProduct = (product: SweflowProduct, kFactor: number | null) => {
    setSelectedCalculatorProduct(product);

    if (kFactor) {
      const roundedKFactor = parseFloat(kFactor.toFixed(3));
      setSelectedCalculatorKFactor(roundedKFactor);
    }

    navigate(routes.calculator);
  };

  const showAirDiffuserInfo = useMemo(() => selectedProduct?.kind === "SweflowAirDiffuser", [selectedProduct]);
  const showWaterProductInfo = useMemo(() => selectedProduct?.kind === "SweflowWaterProduct", [selectedProduct]);

  const productCodeDesc = useMemo(() => {
    const getProductCodeDesc = () => {
      switch (user.cultureSettings.value) {
        case Cultures.svSE:
          return selectedProduct?.donkodDescSv;
        case Cultures.deDE:
          return selectedProduct?.donkodDescDe;
        case Cultures.enUS:
          return selectedProduct?.donkodDescEn;
        default:
          return null;
      }
    };

    return getProductCodeDesc() ?? translator("product_code_description_not_found");
  }, [
    selectedProduct?.donkodDescDe,
    selectedProduct?.donkodDescEn,
    selectedProduct?.donkodDescSv,
    translator,
    user.cultureSettings.value,
  ]);

  const productBoxCodeDesc = useMemo(() => {
    const getProductBoxCodeDesc = (product: SweflowAirDiffuser) => {
      switch (user.cultureSettings.value) {
        case Cultures.svSE:
          return product.boxkodDescSv;
        case Cultures.deDE:
          return product.boxkodDescDe;
        case Cultures.enUS:
          return product.boxkodDescEn;
        default:
          return translator("box_code_description_not_found");
      }
    };

    return selectedProduct?.kind === "SweflowAirDiffuser" ? getProductBoxCodeDesc(selectedProduct) : null;
  }, [selectedProduct, translator, user.cultureSettings.value]);

  const descriptionText = useMemo(() => {
    const getDescriptionText = () => {
      switch (user.cultureSettings.value) {
        case Cultures.svSE:
          return selectedProduct?.descriptionSv;
        case Cultures.deDE:
          return selectedProduct?.descriptionDe;
        case Cultures.enUS:
          return selectedProduct?.descriptionEn;
        default:
          return null;
      }
    };

    return getDescriptionText() ?? translator("description_not_found");
  }, [
    selectedProduct?.descriptionDe,
    selectedProduct?.descriptionEn,
    selectedProduct?.descriptionSv,
    translator,
    user.cultureSettings.value,
  ]);

  return selectedProduct === null ? (
    <Alert severity="info">
      <AlertTitle>{translator("no_product_selected")}</AlertTitle>
      <Button variant="contained" onClick={() => navigate(routes.calculator)}>
        {translator("return_to_calculator")}
      </Button>
    </Alert>
  ) : (
    <Box>
      <BackButton />

      <Stack spacing={1} direction={"column"}>
        <Typography component="h4" variant="h4">
          {selectedProduct.donkod}
        </Typography>
        <Typography component="h6" variant="h6">
          {descriptionText}
        </Typography>

        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ArrowDropDown />}>{translator("product_code_description")}</AccordionSummary>
          <AccordionDetails dangerouslySetInnerHTML={{ __html: productCodeDesc }}></AccordionDetails>
        </Accordion>

        {showAirDiffuserInfo && (
          <>
            {productBoxCodeDesc && productBoxCodeDesc !== "" && (
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary expandIcon={<ArrowDropDown />}>{translator("box_code_description")}</AccordionSummary>
                <AccordionDetails dangerouslySetInnerHTML={{ __html: productBoxCodeDesc }}></AccordionDetails>
              </Accordion>
            )}
            <AirDiffuserKFactorTable airDiffuser={selectedProduct as SweflowAirDiffuser} onAdd={onAddProduct} />
          </>
        )}

        {showWaterProductInfo && (
          <WaterProductKFactorTable waterProduct={selectedProduct as SweflowWaterProduct} onAdd={onAddProduct} />
        )}

        {selectedProduct.fileJpg && (
          <img
            style={{ maxWidth: "450px" }}
            srcSet={selectedProduct.fileJpg}
            src={selectedProduct.fileJpg}
            alt={selectedProduct.don}
            loading="lazy"
          />
        )}

        <SpcLink product={selectedProduct} />

        <Typography component="h4" variant="h4" style={{ fontWeight: "bold" }}>
          {translator("other_information")}
        </Typography>
        {showAirDiffuserInfo && <AirdiffuserLinkList product={selectedProduct as SweflowAirDiffuser} />}
        {showWaterProductInfo && <WaterProductLinkList product={selectedProduct as SweflowWaterProduct} />}
      </Stack>
    </Box>
  );
}
